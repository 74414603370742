import { createSelector } from '@ngrx/store'
import { AppState } from '../app.state'
import { FLinkingToolState } from './f-linking-tool.state'

export const getFLinkingToolState = (state: AppState) => state.fLinkingToolState

/* Grant vs orgranisation */
export const selectGVOSearchOpen = createSelector(getFLinkingToolState, (state: FLinkingToolState) => state.gVOState.isSearchOpen)

export const gVOIsLoading = createSelector(getFLinkingToolState, (state: FLinkingToolState) => state.gVOState.isLoading)

export const gVOData = createSelector(getFLinkingToolState, (state: FLinkingToolState) => state.gVOState.data)

export const downloadGVODataIsLoading = createSelector(getFLinkingToolState, (state: FLinkingToolState) => state.gVOState.isDownloadLoading)

export const gVODataReload = createSelector(getFLinkingToolState, (state: FLinkingToolState) => state.gVOState.needReload)

export const gVOSearchFields = createSelector(getFLinkingToolState, (state: FLinkingToolState) => state.gVOState.searchFields)
/* Manage proposed ideas */
export const mPIDisplayLayout = createSelector(getFLinkingToolState, (state: FLinkingToolState) => state.mPIState.displayLayout)

export const mPIDataIsLoading = createSelector(getFLinkingToolState, (state: FLinkingToolState) => state.mPIState.isLoading)

export const mPIData = createSelector(getFLinkingToolState, (state: FLinkingToolState) => state.mPIState.data)

export const mPIDataReload = createSelector(getFLinkingToolState, (state: FLinkingToolState) => state.mPIState.needReload)

export const mPISearchFields = createSelector(getFLinkingToolState, (state: FLinkingToolState) => state.mPIState.searchFields)

/* Projects seeking partners */
export const pSPDisplayLayout = createSelector(getFLinkingToolState, (state: FLinkingToolState) => state.pSPState.displayLayout)

export const pSPDataIsLoading = createSelector(getFLinkingToolState, (state: FLinkingToolState) => state.pSPState.isLoading)

export const pSPData = createSelector(getFLinkingToolState, (state: FLinkingToolState) => state.pSPState.data)
